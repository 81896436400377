<template>
  <div>
    <div class="comtent_box">
      <img src="../../assets/success.png" alt="" />
      <!-- <img v-else src="../../assets/error.png" alt="" /> -->
      <p>{{ payStatus == 2 ? "支付成功" : "资料提交完成" }}</p>
      <p class="font16 color33">
        请耐心等待审核，可点击个人头像-我的报考，查看
        <span class="exam_text" @click="toMysign">审核进度</span>
      </p>
    </div>

    <!-- <div class="btn_list">
      <el-button plain class="last_btn" @click="toBackIndex"
        >返回首页</el-button
      >
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      payStatus: "",
    };
  },
  mounted() {
    this.payStatus = window.localStorage.getItem("paystatus");
  },
  methods: {
    toBackIndex() {
      this.$router.replace("/");
    },
    toMysign() {
      this.$router.replace("/user/mySign");
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 81px;
  height: 81px;
  margin: 50px 0 20px;
}
.comtent_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 20px 0;
  }
}
.btn_list {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  button {
    width: 130px;
    height: 32px;
    line-height: 32px;
    border-radius: 10px;
    padding: 0;
    line-height: 32px;
    margin: 30px;
  }
  .last_btn {
    background-color: #fff;
    border: 1px solid #2b68d9;
    color: #2b68d9;
  }
}
.exam_text {
  color: #2b68d9;
  cursor: pointer;
}
</style>
