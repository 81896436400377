<template>
  <div class="step3 box">
    <div class="title">
      <i class="el-icon-caret-right icon"></i>
      <span class="font26 color33">报名缴费</span>
      <i class="el-icon-caret-left icon"></i>
    </div>
    <p class="font18 color33 tips">报考信息确认</p>
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      class="my_table font18 color33"
    >
      <tr>
        <td>姓名：</td>
        <td v-if="userInfo">{{ userInfo.name }}</td>
      </tr>
      <tr>
        <td>身份证号：</td>
        <td v-if="userInfo">{{ userInfo.idCard }}</td>
      </tr>
      <tr>
        <td>申报职业：</td>
        <td>{{ info.skillCategoryFirstName }}</td>
      </tr>
      <tr>
        <td>报考工种：</td>
        <td>{{ info.skillCategorySecondName }}</td>
      </tr>
      <tr>
        <td>申报级别：</td>
        <td>{{ info.skillCategoryThirdName }}</td>
      </tr>
      <tr>
        <td>订单编号：</td>
        <td>{{ orderNo }}</td>
      </tr>
      <tr>
        <td>报考费用：</td>
        <td>{{ info.examFee }}元</td>
      </tr>
    </table>
    <div class="upload_box" v-if="type == 3">
      <div class="pay_user">
        <div class="pay_info">
          <p class="title">
            请参与人员<i>5个工作日内</i>将认定费用汇款至以下账户：
          </p>
          <div class="pay_info_box">
            <p>账户名称：{{ companyInfo.accoutName }}</p>
            <p>开户银行：{{ companyInfo.bankName }}</p>
            <p>银行账号：{{ companyInfo.bankNumber }}</p>
          </div>
          <p class="bottom_title">
            汇款时请在汇款用途注明：姓名+操作员+级别+认定费
          </p>
          <p class="bottom_title_text">（例：<i>张三操作员四级认定费</i>）</p>
        </div>
      </div>
      <div class="title_box">
        <span class="title_left">支付凭证</span>
      </div>

      <div class="form_box">
        <span>交易订单截图</span>
        <el-upload
          class="upload-demo"
          action="#"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          list-type="picture"
          :auto-upload="false"
          :on-change="changeViewOrder"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/jpeg文件，且不超过5MB
          </div>
        </el-upload>
      </div>
    </div>
    <p class="font18 color33 tips">支付方式</p>
    <div class="pay_type" v-if="showPay">
      <!-- v-if="payConfig.wxpayControl == 1" -->
      <div
        class="pay_item"
        :class="type == 1 ? 'active' : ''"
        @click="type = 1"
      >
        <img src="../../assets/pay1.png" alt="" />
      </div>
      <!-- v-if="payConfig.alipayControl == 1" -->
      <div
        class="pay_item"
        :class="type == 2 ? 'active' : ''"
        @click="type = 2"
      >
        <img src="../../assets/pay2.png" alt="" />
      </div>
      <!-- v-if="payConfig.offlineControl == 1" -->
      <div
        class="pay_item"
        :class="type == 3 ? 'active' : ''"
        @click="type = 3"
      >
        <img src="../../assets/pay3.png" alt="" />
      </div>
    </div>
    <div class="pay_type" v-else>
      <!-- v-if="payConfig.wxpayControl == 1" -->
      <div
        class="pay_item"
        :class="type == 1 ? 'active' : ''"
        @click="type = 1"
        v-if="payConfig.wxpayControl == 1"
      >
        <img src="../../assets/pay1.png" alt="" />
      </div>
      <!-- v-if="payConfig.alipayControl == 1" -->
      <div
        class="pay_item"
        :class="type == 2 ? 'active' : ''"
        @click="type = 2"
        v-if="payConfig.alipayControl == 1"
      >
        <img src="../../assets/pay2.png" alt="" />
      </div>
      <!-- v-if="payConfig.offlineControl == 1" -->
      <div
        class="pay_item"
        :class="type == 3 ? 'active' : ''"
        @click="type = 3"
        v-if="payConfig.offlineControl == 1"
      >
        <img src="../../assets/pay3.png" alt="" />
      </div>
    </div>

    <div class="tip_box" v-if="companyInfo.phone">
      <p class="font14">
        温馨提示：请认真确认您的报考信息，信息有误可返回上一步修改，如有疑问请拨打咨询电话：{{
          companyInfo.phone
        }}
      </p>
    </div>

    <div class="btn_list">
      <el-button plain class="last_btn" @click="lastStep">上一步</el-button>
      <el-button
        type="primary"
        v-if="type == 3"
        class="next_btn"
        autofocus
        @click="toStep"
        >确认支付</el-button
      >
      <el-button type="primary" v-else class="next_btn" autofocus @click="toPay"
        >支付</el-button
      >
    </div>
    <el-image-viewer
      v-if="showOrder"
      :on-close="
        () => {
          showOrder = false;
        }
      "
      :url-list="[fileList[0].url]"
    />
    <el-dialog
      title="报名缴费"
      :visible.sync="showWxPay"
      width="50%"
      :close-on-click-modal="false"
      :before-close="closePay"
    >
      <div class="img">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
      <p class="tip">打开微信 '扫一扫' 报名缴费</p>
    </el-dialog>
  </div>
</template>

<script>
import {companyInfo} from "@/util/api";
import {mapState} from "vuex";
import QRCode from "qrcodejs2";
import {
  getPlanInfo,
  getPaymentConfig,
  uploadPaymentImage,
  prepay,
  getOrderStatus,
} from "@/util/api";
export default {
  data() {
    return {
      type: null,
      info: {},
      payConfig: {},
      fileList: [],
      showOrder: false,
      orderNo: null,
      showWxPay: false,
      showCode: "",
      orderNo: null,
      companyInfo: "",
      showPay: false,
    };
  },
  mounted() {
    this.toGetPlanInfo();
    this.getPayConfig();
    this.orderNo = window.localStorage.getItem("orderNo");
    console.log(this.orderNo);
    companyInfo({domainUrl: window.location.hostname}).then((res) => {
      this.companyInfo = res.data;
    });
    console.log(this,info.skillCategoryFirstName);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    handlePreview(file) {
      this.showOrder = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    changeViewOrder(res, file) {
      const isLt10M = res.size / 1024 < 500;
      if (["image/jpeg", "image/jpg"].indexOf(res.raw.type) == -1) {
        this.$message.error("请上传正确的图片格式");
        this.fileList = [];
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传图片不能超过5MB哦!");
        this.fileList = [];
        return false;
      }
      let that = this;
      const fr = new FileReader();
      fr.readAsDataURL(res.raw);
      fr.onload = function () {
        that.fileList = [{url: fr.result, name: "支付截图"}];
        that.$forceUpdate();
      };
    },
    toGetPlanInfo() {
      getPlanInfo({
        planId: this.$route.query.planId,
        domainUrl: window.location.hostname,
      }).then((res) => {
        this.info = res.data;
      });
    },
    getPayConfig() {
      getPaymentConfig({domainUrl: window.location.hostname}).then((res) => {
        this.payConfig = res.data;
        if (
          this.payConfig.wxpayControl == 0 &&
          this.payConfig.alipayControl == 0 &&
          this.payConfig.offlineControl == 0
        ) {
          this.showPay = true;
        } else {
          this.showPay = false;
        }
      });
    },
    lastStep() {
      if (this.$route.query.isNotFree == 3) {
        this.$emit("changeStep", 1);
      } else {
        this.$emit("changeStep", 3);
      }
    },
    toStep() {
      if (this.type == 3 && this.payConfig.offlineControl == 0) {
        this.$message.warning("暂不支持该支付方式");
        return;
      }
      window.sessionStorage.removeItem("myObj");
      if (this.fileList.length != 0 && this.fileList[0].url) {
        uploadPaymentImage({
          paymentImageData: this.fileList[0].url,
          orderNo: this.orderNo,
        }).then((res) => {
          this.$route.query.isNotFree == 3
            ? this.$emit("changeStep", 3)
            : this.$route.query.isNotFree == 2
            ? this.$emit("changeStep", 5)
            : this.$emit("changeStep", 5);
          window.localStorage.setItem("paystatus", 2);
        });
      } else {
        this.$message.warning("请上传交易订单截图");
      }
    },
    // 关闭微信支付
    closePay() {
      this.$route.query.isNotFree == 3 ? this.$emit("changeStep", 3) : "";
      this.showWxPay = false;
      this.$message.warning("支付失败");
      this.showCode = null;
      // this.payStatus = false;
      clearInterval(this.payTimer);
    },
    //
    toPay() {
      if (
        (this.type == 1 && this.payConfig.alipayControl == 0) ||
        (this.type == 2 && this.payConfig.wxpayControl == 0)
      ) {
        this.$message.warning("暂不支持该支付方式");
        return;
      }

      prepay({
        orderNo: this.orderNo,
        payType: this.type == 1 ? "ALIPAY" : this.type == 2 ? "WXPAY" : "",
        tradeType: "NATIVE",
      })
        .then((res) => {
          if (this.type == 1) {
            window.sessionStorage.setItem("myObj", true);
            // this.$rouer.go(-1);
            document.querySelector("body").innerHTML = res.data;
            document.forms[0].submit();
          } else {
            window.sessionStorage.removeItem("myObj");
            this.showWxPay = true;
            this.showCode = res.data;
            if (window.document.getElementsByClassName("qrcode")[0]) {
              window.document.getElementsByClassName("qrcode")[0].innerHTML =
                "";
            }
            this.creatQrCode(this.showCode);
            this.callBackPay(this.orderNo);
          }
        })
        .catch((res) => {});
    },
    creatQrCode(showCode) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeUrl, {
          text: showCode, // 需要转换为二维码的内容
          width: 250,
          height: 250,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    callBackPay(orderNo) {
      this.payTimer = setInterval(() => {
        getOrderStatus({
          orderNo: this.orderNo,
        })
          .then((res) => {
            if (res.data == 1) {
              this.showWxPay = false;
              this.showCode = null;
              this.$route.query.isNotFree == 3
                ? this.$emit("changeStep", 3)
                : this.$route.query.isNotFree == 2
                ? this.$emit("changeStep", 5)
                : this.$emit("changeStep", 5);
              clearInterval(this.payTimer);
              window.localStorage.setItem("paystatus", 2);
            }
          })
          .catch(() => {
            this.$route.query.isNotFree == 3 ? this.$emit("changeStep", 3) : "";
            this.$message.warning("支付失败");
            clearInterval(this.payTimer);
          });
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  .icon {
    color: #2b68d9;
    margin: 0 10px;
  }
}
.tips {
  margin-top: 20px;
}
.my_table {
  margin-top: 15px;
  border: 1px #d5e1f7 solid;
  td {
    border: 1px #d5e1f7 solid;
  }
  tr td:first-of-type {
    width: 120px;
    padding: 13px 30px;
  }
  tr td:last-of-type {
    width: 640px;
    padding: 13px 30px;
  }
}
.pay_type {
  margin-top: 15px;
  display: flex;
  .pay_item {
    cursor: pointer;
    width: 120px;
    height: 40px;
    border: 2px solid #f5f5f5;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    img {
      height: 19px;
      width: auto;
      // width: 55px;
    }
  }
  .active {
    background-image: url("../../assets/check_pay.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.qrcode {
  width: 250px;
  height: 250px;
}
.img {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrcode {
    width: 250px;
    height: 250px;
    background-color: #42ef42;
    img {
      width: 100%;
      height: 100px;
    }
  }
}
.tip {
  color: #333333;
  font-size: 16;
  margin: 20px;
  text-align: center;
}
.tip_box {
  width: 885px;
  height: 40px;
  background: #d5e1f7;
  border-radius: 10px;
  margin-top: 30px;
  p {
    color: #2b68d9;
    padding: 0 10px;
    line-height: 40px;
  }
}
.btn_list {
  display: flex;
  justify-content: center;
  button {
    width: 130px;
    height: 32px;
    line-height: 32px;
    border-radius: 10px;
    padding: 0;
    line-height: 32px;
    margin: 30px;
  }
  .last_btn {
    background-color: #fff;
    border: 1px solid #2b68d9;
    color: #2b68d9;
  }
  .next_btn {
    background: #2b68d9;
  }
}
.upload_box {
  margin: 0px auto 20px;
  .pay_box {
    width: 50%;
    margin: 0 auto 30px;
    text-align: center;
    p {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 14px;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }
  .title_box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .title_left {
      font-size: 16px;
      font-weight: bold;
    }
    .title_right {
      color: #2b68d9;
      cursor: pointer;
    }
    .title_right:hover {
      text-decoration: underline;
    }
  }
  .form_box {
    width: 70%;
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    span {
      display: inline-block;
      width: 100px;
      line-height: 40px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .pay_info {
    margin-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid #f2f2f2;
    .title {
      font-size: 16px;
      font-weight: bold;
      i {
        color: red;
        font-style: normal;
        margin: 0 5px;
      }
    }
    div {
      margin: 20px;
      p {
        line-height: 35px;
        font-size: 16px;
      }
    }
    .bottom_title {
      font-size: 16px;
    }
    .bottom_title_text {
      margin-top: 10px;
      i {
        color: red;
        font-style: normal;
        margin: 0 5px;
      }
    }
  }
}
.img img {
  width: 100%;
  height: 100%;
}
</style>
