<template>
  <div class="web">
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item>报考须知</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="inner">
        <div class="left_box">
          <li v-for="item in stepList" :key="item.id">
            <span
              class="step_num"
              :class="item.id <= navid ? 'step_active' : ''"
              ><i class="num colorff font14">{{ item.step }}</i></span
            >
            <span
              class="font14 color99 step_name"
              :class="item.id <= navid ? 'active_name' : ''"
              >{{ item.name }}</span
            >
          </li>
        </div>
        <div></div>
        <div class="right_box" v-if="isNotFree == 2">
          <div v-if="navid == 1">
            <step1 @changeStep="changeStep"></step1>
          </div>
          <div v-if="navid == 2">
            <step2 @changeStep="changeStep"></step2>
          </div>
          <div v-if="navid == 3">
            <step3 @changeStep="changeStep"></step3>
          </div>
          <div v-if="navid == 4">
            <step4 @changeStep="changeStep"></step4>
          </div>
          <div v-if="navid == 5">
            <step5 @changeStep="changeStep"></step5>
          </div>
        </div>
        <div class="right_box" v-else-if="isNotFree == 1">
          <div v-if="navid == 1">
            <step1 @changeStep="changeStep"></step1>
          </div>
          <div v-if="navid == 2">
            <step2 @changeStep="changeStep"></step2>
          </div>
          <div v-if="navid == 3">
            <step3 @changeStep="changeStep"></step3>
          </div>
          <div v-if="navid == 5">
            <step5 @changeStep="changeStep"></step5>
          </div>
        </div>
        <div class="right_box" v-else>
          <div v-if="navid == 1">
            <step1 @changeStep="changeStep"></step1>
          </div>
          <div v-if="navid == 2">
            <step4 @changeStep="changeStep"></step4>
          </div>
          <div v-if="navid == 3">
            <step5 @changeStep="changeStep"></step5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import step1 from "@/components/process/step1.vue";
import step2 from "@/components/process/step2-1.vue";
import step3 from "@/components/process/step3.vue";
import step4 from "@/components/process/step4.vue";
import step5 from "@/components/process/step5.vue";

export default {
  components: {
    step1,
    step2,
    step3,
    step4,
    step5,
  },
  data() {
    return {
      navid: 1,
      stepList: [],
      isNotFree: 1,
    };
  },
  mounted() {
    if (window.sessionStorage.getItem("myObj")) {
      if (this.$route.query.isNotFree != 3) {
        this.navid = 4;
      } else {
        this.navid = 3;
      }
    }
    this.isNotFree = this.$route.query.isNotFree;
    if (this.$route.query.isNotFree == 1) {
      this.stepList = [
        {id: 1, name: "报考须知", step: 1},
        {id: 2, name: "填写基本资料", step: 2},
        {id: 3, name: "上传资料", step: 3},
        {id: 4, name: "完成", step: 4},
      ];
    } else if (this.$route.query.isNotFree == 2) {
      this.stepList = [
        {id: 1, name: "报考须知", step: 1},
        {id: 2, name: "填写基本资料", step: 2},
        {id: 3, name: "上传资料", step: 3},
        {id: 4, name: "缴费", step: 4},
        {id: 5, name: "完成", step: 5},
      ];
    } else {
      this.stepList = [
        {id: 1, name: "报考须知", step: 1},
        {id: 2, name: "缴费", step: 2},
        {id: 3, name: "完成", step: 3},
      ];
    }
    this.$forceUpdate();
  },
  methods: {
    changeStep(i) {
      this.navid = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.web {
  background-color: #f5f5f5;
  min-height: 700px;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }
}
.inner {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .left_box,
  .right_box {
    background: #fff;
    border-radius: 10px;
  }
  .left_box {
    width: 190px;
    position: relative;
    padding: 43px 0;
    li {
      list-style: none;
      padding: 40px 0;
      display: flex;
      align-items: center;
      margin-left: 15px;

      .step_num {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #d8d8d8;
        border-radius: 50%;
        text-align: center;
        position: relative;
        line-height: 28px;
        .num {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          left: 0;
          top: 0;
          line-height: 28px;
          font-style: normal;
          z-index: 3;
        }
      }
      .step_num::after {
        content: "";
        width: 22px;
        height: 22px;
        background: #acacac;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 3px;
      }
      .step_active {
        background: #729dec;
      }
      .step_active::after {
        background-color: rgba($color: #2b68d9, $alpha: 0.8);
      }
    }
    .step_name {
      margin-left: 20px;
    }
    .active_name {
      color: #2b68d9;
    }
  }
  .left_box::before {
    position: absolute;
    content: "";
    height: 100%;
    border-left: 1px dashed #cccccc;
    left: 30px;
    top: 0;
  }
}
.right_box {
  flex: 1;
  margin-left: 20px;
  padding: 30px 50px;
  margin-bottom: 80px;
}
</style>
