<template>
  <div class="step1_bxo">
    <div class="title">
      <i class="el-icon-caret-right icon"></i>
      <span class="font26 color33">报考须知</span>
      <i class="el-icon-caret-left icon"></i>
    </div>
    <div class="inner_text font14 color33">
      <div v-html="content"></div>
    </div>

    <div class="btn my_gray" v-if="!isTrue && content">
      <el-button disabled>我已知晓（{{ time }}）</el-button>
    </div>
    <div class="btn" v-else>
      <el-button type="primary" @click="nextStep(2)">下一步</el-button>
    </div>
  </div>
</template>

<script>
import {getExamExplan, generateOrder, signUp} from "@/util/api";
export default {
  data() {
    return {
      time: 10,
      isTrue: false,
      content: "",
      isNotFree: null,
    };
  },
  mounted() {
    this.isNotFree = this.$route.query.isNotFree;
    let timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        this.isTrue = true;
        clearInterval(timer);
      }
    }, 1000);
    getExamExplan({
      planId: this.$route.query.planId,
    }).then((res) => {
      this.content = res.data.content;
    });
  },
  methods: {
    nextStep(i) {
      if (this.isNotFree == 3) {
       if(this.$route.query.partnerId == 'null'){
        signUp({
          planId: this.$route.query.planId,
        }).then((res) => {
          generateOrder({
            planId: this.$route.query.planId,
          }).then((res) => {
            // this.orderNo = res.orderNo;
            this.$notify({
              title: "成功",
              message: "报名成功",
              type: "success",
            });
            window.localStorage.setItem("orderNo", res.data.orderNo);
            this.$emit("changeStep", i);

            // window.localStorage.setItem("payStatus", 2);
          });
        });
       }else{
        signUp({
          planId: this.$route.query.planId,
          partnerId: this.$route.query.partnerId,
        }).then((res) => {
          generateOrder({
            planId: this.$route.query.planId,
          }).then((res) => {
            // this.orderNo = res.orderNo;
            this.$notify({
              title: "成功",
              message: "报名成功",
              type: "success",
            });
            window.localStorage.setItem("orderNo", res.data.orderNo);
            this.$emit("changeStep", i);

            // window.localStorage.setItem("payStatus", 2);
          });
        });
       }
        return;
      }
      this.$emit("changeStep", i);
    },
  },
};
</script>
<style scoped>
.step1_bxo >>> img {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.title {
  text-align: center;
  .icon {
    color: #2b68d9;
    margin: 0 10px;
  }
}
.inner_text {
  padding: 20px 0;
}
.btn {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 180px;
    height: 32px;
    background: #2b68d9;
    border-radius: 16px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
  }
}
.my_gray {
  button {
    background-color: #b6b6b6 !important;
    color: rgb(255, 255, 255);
  }
  button:hover {
    color: rgb(255, 255, 255);
  }
}
</style>
